<template>
<div class="organizationBox">
<img src="../assets/img/ldbz.png" alt="">
</div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped>
.organizationBox {
  text-align: center;
}
</style>

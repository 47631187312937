<template>
<div class="list">

<div class="listMain wrap">
<div class="listMainBox">
<div class="listMainLeft fl">
<div class="listSlid">
<div class="listSlidHd">
              建投站群
            </div>
<div class="listSlidList">
<div class="listSlidListInner" :class="{on : active  == item.entTChannelUuid}" v-for="(item, index) in slideList" :key="index">
<a 
                  v-if="item.url && item.url !== ''" 
                  target="_blank" 
                  :href="item.url">
                  {{item.name}}
                </a>
<a 
                  v-if="!item.url || item.url == ''" 
                  :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                  {{item.name}}
                </a>
<span></span>
</div>
</div>
</div>
</div>
<div class="listMainRight fr">
<div class="listWrap">
<div class="listHd clearfloat">
<div class="fl listHdGrop">
<span class="listHdIco"><i class="el-icon-s-home"></i></span>您当前所在位置：<a href="/">首页</a>><span v-if="viewSlideHdParent"><a :href="slideHdParentUrl">{{slideHdParentName}}</a>></span><span class="txt">建投站群</span>
</div>
</div>
<div class="listContainer">
<div class="organizationTitle">组织机构</div>
<div class="organizationHd">
<span
                  v-for="(item, index) in organizationHdList"
                  :class="{ active: activeHd == index }"
                  :key="index"
                  @mouseenter="mouseenterHd(index)"
                  >{{ item.name }}</span
                >
</div>
<div class="organizationWrap">
<inside v-show="activeHd == 0" ref="inside"></inside>
<subcompany v-show="activeHd == 1" ref="inside"></subcompany>
<outside v-show="activeHd == 2" ref="inside"></outside>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</template>
<script>
import { getChildChannel, getChannelInfo, articles } from '@/api/common'
import inside from '@/components/inside.vue'
import subcompany from '@/components/subcompany.vue'
import outside from '@/components/outside.vue'

export default {
  components: {
    inside,
    subcompany,
    outside
  },
  name: 'list',
  data(){
    return {
      slideHdTitle: '',
      viewSlideHdParent: false,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '新闻动态',
      },
      slideList:[],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listData: [],
      listId: '',
      organizationHdList: [
        {
          name: '内部机构'
        },
        {
          name: '子公司'
        },
        {
          name: '驻外机构'
        }
      ],
      activeHd: 1
    }
  },
  mounted(){
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // _this.getChildChannel()
    // _this.getChannelInfo()
    _this.articles()
  },
  methods: {
    mouseenterHd(val) {
      var _this = this

      _this.activeHd = val
    },
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/listPic/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id
      }
      getChannelInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
          if (data.parentUuid == "0") {
            _this.slideHdTitle = data.name
            _this.viewSlideHdParent = false
            return
          }

          _this.getChildChannel(data.parentUuid)

          let params = {
            channelUuid: data.parentUuid
          }
          getChannelInfo(params).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              _this.slideHdParentName = data.name
              _this.slideHdTitle = data.name
            }
          })
        }
      })
    },
    // 获取列表
    articles() {
      var _this = this
      let data = {
        title: _this.$route.params.title,
        pageNum: _this.pageNum,
        pageSize: _this.pageSize,
        entTApplicationUuid: _this.webId
      }
      articles(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.listData = data.rows
          _this.total = data.totalRows
        }
      })
    },
    currentChange(e){
      // console.log('e :>> ', e);
      var _this = this
      _this.pageNum = e
      _this.articles()
    }
  }
}
</script>
<style scoped>
.organizationTitle {
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  padding-top: 20px;
}
.organizationHd {
  text-align: center;
  margin: 20px 0;
}
.organizationHd span {
  display: inline-block;
  width: 248px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  background-color: #ebf1f5;
  cursor: pointer;
}
.organizationHd span.active {
  background-color: #0162b3;
  color: #ffffff;
}
</style>